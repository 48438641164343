import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import commonReducer from '../slices/common/commonSlice.tsx';
import cartReducer from '../slices/shopping/cartSlice.tsx';
import checkOutReducer from '../slices/shopping/checkOutSlice.tsx';
import productsSliceReducer from '../slices/shopping/productsSlice.jsx';
import categoryReducer from '../slices/shopping/categorySlice.jsx';
import dieuKienSaleReducer from '../slices/shopping/dieuKienSaleSlice.jsx';
import shippingReducer from '../slices/shopping/shippingSlice.jsx';
import paymentReducer from '../slices/shopping/paymentSlice.jsx';
import campaignReducer from '../slices/shopping/campaignSlice.jsx';
import cuponReducer from '../slices/shopping/cuponSlice.jsx';
import promotionReducer from '../slices/shopping/promotionSlice.jsx';
import orderReducer from '../slices/shopping/orderSlice.jsx';
import authReducer from '~redux/slices/auth/authSlice.tsx';

const rootReducer = {
    auth: authReducer,
    cart: cartReducer,
    product: productsSliceReducer,
    category: categoryReducer,
    dieuKienSale: dieuKienSaleReducer,
    shipping: shippingReducer,
    payment: paymentReducer,
    campaign: campaignReducer,
    cupon: cuponReducer,
    promotion: promotionReducer,
    order: orderReducer,
    checkOutInfo: checkOutReducer,
    common: commonReducer,
};

const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
