import { decryptData } from '~hooks/auth/cryptoHelpers';

const useAuth = () => {
    const encryptedRole = localStorage.getItem('ConfigUser') || sessionStorage.getItem('ConfigUser') || null;
    const decryptedRole = encryptedRole ? decryptData(encryptedRole) : null;
    const userInfo = decryptedRole ?? "";
    const isLogIn = decryptedRole?.status === 'Active';
    const isCEO = decryptedRole?.role === 'CEO';

    // console.log('userInfo:', userInfo);
    // console.log('isLogIn:', isLogIn);
    // console.log('isCEO:', isCEO);

    return { isLogIn, isCEO, userInfo};
};
export default useAuth;
