import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";

const fetchShippings = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/DichVuVanChuyen?sort=maDichVuVanChuyen,asc`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
        const data = response.data;
        return data;
    } catch (error) {
        throw new Error('failed');
    }
};

const searchShippings = async (searchTerm, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/DichVuVanChuyen?sort=maDichVuVanChuyen,asc&search=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
        return response.data;
    } catch (error) {
        throw new Error('Failed to search Shippings');
    }
};

const sortShippingsById = async (order, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const sortOption = order === 'asc' ? 'maDichVuVanChuyen,asc' : 'maDichVuVanChuyen,desc';
        const response = await api.get(`/DichVuVanChuyen?sort=${sortOption}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
        return response.data;
    } catch (error) {
        throw new Error('Failed to sort Shippings by id');
    }
};

const createShipping = async (data) => {
    try {
        const token = getAccessToken();
        const response = await api.post('/DichVuVanChuyen', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createdProduct = response.data;
        return createdProduct;
    } catch (error) {
        throw new Error('Creating Shippings failed');
    }
};


const updateShipping = async (id, data) => {
    try {
        const token = getAccessToken();
        const response = await api.patch(`/DichVuVanChuyen/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Change Shippings failed');
    }
};

const deleteShipping = async (id) => {
    try {
        const token = getAccessToken();
        await api.delete(`/DichVuVanChuyen/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return 'Shippings deleted successfully';
    } catch (error) {
        throw new Error('Deleting Shippings failed');
    }
};

const ShippingsService = {
    fetchShippings,
    searchShippings,
    sortShippingsById,
    createShipping,
    updateShipping,
    deleteShipping
}
export default ShippingsService;