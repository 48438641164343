import { getAccessToken } from "~utils/helper";
import axios from "../commonService/apiConfig";


// const UserId = getUserId() || 100000000;

// Giỏ hàng cá nhân
const fetchCart = async (UserId) => {
    try {
        const token = getAccessToken();
        const response = await axios.get(`/GioHang/${UserId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    }
    catch (error) {
        throw new Error('Get Cart failed');
    }
}

// Tạo giỏ hàng
const createCart = async (data) => {
    const configData = {
        isMuaSau: false,
        ...data
    }
    try {
        const token = getAccessToken();
        const response = await axios.post('/GioHang', configData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createCart = response.data;
        return createCart;
    } catch (error) {
        throw new Error('Creating Cart failed');
    }
};


// Sửa giỏ hàng
const updateCart = async (data) => {
    try {
        const token = getAccessToken();
        const response = await axios.patch('/GioHang', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createCart = response.data;
        return createCart;
    } catch (error) {
        throw new Error('Update Cart failed' + error.message);
    }
};



// Xóa giỏ hàng
const deleteCartItem = async (UserId, ProductId, note) => {
    try {
        const token = getAccessToken();
        const response = await axios.delete(`GioHang/${UserId}/${ProductId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: note || null
        });
        const createCart = response.data;
        return createCart;
    } catch (error) {
        throw new Error('Delete Cart Item failed: ' + error.message);
    }
}

// Lấy địa chỉ
const getDiaChi = (maDiaChi) => {
    try {
        const token = getAccessToken();
        const response = axios.get(`/DiaChi/${maDiaChi}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    }
    catch (error) {
        throw new Error('Get Address failed');
    }
}

// Lấy danh sách phương thức vận chuyển
const getDeliveryMethods = () => {
    try {
        const token = getAccessToken();
        const response = axios.get(`/DichVuVanChuyen`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        throw new Error('Get Delivery Methods failed');
    }
}


// Lấy danh sách phương thức thanh toán
const getPaymentMethods = () => {
    try {
        const token = getAccessToken();
        const response = axios.get(`/PhuongThucThanhToan`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        throw new Error('Get Payment Methods failed');
    }
}

// Chuyển sản phẩm vào mục mua sau
const moveToBuyLater = async (data) => {
    try {
        const token = getAccessToken();
        const response = await axios.patch('/GioHang/moveToMuaSau', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createCart = response.data;
        return createCart;
    } catch (error) {
        throw new Error('Move to Buy Later failed');
    }
};

// Chuyển sản phẩm vào giỏ hàng
const moveToCart = async (data) => {
    try {
        const token = getAccessToken();
        const response = await axios.patch('/GioHang/moveToCart', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createCart = response.data;
        return createCart;
    } catch (error) {
        throw new Error('Move to Cart failed');
    }
};

// Xóa sản phẩm khỏi mục mua sau
const deleteBuyLaterItem = async (UserId, ProductId) => {
    try {
        const token = getAccessToken();
        const response = await axios.delete(`MuaSau/${UserId}/${ProductId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createCart = response.data;
        return createCart;
    } catch (error) {
        throw new Error('Delete Buy Later Item failed' + error.message);
    }
}

const CartsService = {
    getDeliveryMethods,
    getPaymentMethods,
    fetchCart,
    deleteCartItem,
    getDiaChi,
    createCart,
    updateCart,
    moveToBuyLater,
    moveToCart,
    deleteBuyLaterItem
}

export default CartsService;