import api from "../commonService/apiConfig";
import { getAccessToken } from "~utils/helper";

const fetchCategories = async () => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/LoaiSanPham?sort=maLoaiSanPham,asc`,{
      headers: {
          Authorization: `Bearer ${token}`,
      },
  }
);
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error('failed');
  }
};

const CategoryService = {
  fetchCategories
}
export default CategoryService;