import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSearchBarOpen: false,
    searchKeyword: "",
};

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        toggleSearchBar: (state) => {
            state.isSearchBarOpen = !state.isSearchBarOpen;
        },
        setSearchKeyword: (state: any, action: any) => {
            state.searchKeyword = action.payload.searchKeyword;
        },
        error: (state: any, action: any) => {
            throw new Error(`Unknown action: ${action.type}`);
        },

    }
});

export const { toggleSearchBar, error, setSearchKeyword } = commonSlice.actions;

export default commonSlice.reducer;
