import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";

const fetchCampaigns = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/SuKien?sort=maSuKien,asc`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        );
        const data = response.data;
        return data;
    } catch (error) {
        throw new Error('failed');
    }
};

const searchCampaigns = async (searchTerm, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/SuKien?sort=maSuKien,asc&search=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        );
        return response.data;
    } catch (error) {
        throw new Error('Failed to search Campaigns');
    }
};

const sortCampaignsById = async (order, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const sortOption = order === 'asc' ? 'maSuKien,asc' : 'maSuKien,desc';
        const response = await api.get(`/SuKien?sort=${sortOption}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        );
        return response.data;
    } catch (error) {
        throw new Error('Failed to sort Campaigns by id');
    }
};

const createCampaign = async (data) => {
    try {
        const token = getAccessToken();
        const response = await api.post('/SuKien', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createdProduct = response.data;
        return createdProduct;
    } catch (error) {
        throw new Error('Creating Campaigns failed');
    }
};


const updateCampaign = async (id, data) => {
    try {
        const token = getAccessToken();
        const response = await api.patch(`/SuKien/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Change Campaigns failed');
    }
};

const deleteCampaign = async (id) => {
    try {
        const token = getAccessToken();
        await api.delete(`/SuKien/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return 'Campaigns deleted successfully';
    } catch (error) {
        throw new Error('Deleting Campaigns failed');
    }
};

const CampaignsService = {
    fetchCampaigns,
    searchCampaigns,
    sortCampaignsById,
    createCampaign,
    updateCampaign,
    deleteCampaign
}
export default CampaignsService;