import React from "react";
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { useResponsive } from '~hooks/use-responsive.tsx';
import { bgBlur } from '~components/theme/css.tsx';
import { HEADER } from '../component/config-layout.tsx';
import AccountPopover from '../component/account-popover.tsx';
import CartPopover from '../component/cart-popover.tsx';
import { useNavigate, useLocation } from 'react-router-dom';

// @ts-ignore
const Header = ({ onOpenNav, menuControl }) => {
    const theme = useTheme();

    const location = useLocation();

    const isGroupOrder = location.pathname.includes('/group_order');

    // @ts-ignore
    const lgUp = useResponsive('up', 'lg');

    const navigate = useNavigate();

    const renderContent = (
        <>
            {/* {!lgUp && (
                <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
                    <
                        // @ts-ignore
                        Iconify icon="eva:menu-2-fill" />
                </IconButton>
            )} */}

            {/*// @ts-ignore*/}
            <img
                height={"60px"}
                width={"auto"}
                src="https://firebasestorage.googleapis.com/v0/b/teepressso.appspot.com/o/Main%2Ftrasua3.png?alt=media&token=e54c198a-0036-484b-9bf1-269c57c8f366" alt="logo"
                onClick={() => navigate && navigate('/')}
            />
            {/* <Searchbar sx={{ flexGrow: 1 }} /> */}

            {/*<Box sx={{flexGrow: 1}}/>*/}

            <div style={{ marginLeft: "auto" }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    {/* <LanguagePopover /> */}
                    {/* <NotificationsPopover menuControl={menuControl} /> */}
                    {/* //Ngăn hành động click vào các ô khác làm thay đổi trạng thái của giỏ hàng */}

                    {isGroupOrder ? null :
                        <div onClick={(e) => e.stopPropagation()}>
                            {/*// @ts-ignore*/}
                            <CartPopover />
                            {/* {menuControl && <CartPopover />} */}
                        </div>
                    }

                    {/* <DarkModeToggler /> */}
                    {/* <LanguagePopover /> */}
                    {/* <NotificationsPopover menuControl={menuControl} /> */}
                    {/* <AccountPopover menuControl={menuControl} /> */}

                    <AccountPopover menuControl={menuControl} />
                </Stack>
            </div>
        </>
    );

    return (
        <>
            {/* <HomeBanner /> */}
            <AppBar
                // @ts-ignore
                sx={{
                    boxShadow: 'none',
                    height: HEADER.H_MOBILE,
                    zIndex: 1000,
                    ...bgBlur({
                        color: theme.palette.background.default,
                    }),
                    transition: theme.transitions.create(['height'], {
                        duration: theme.transitions.duration.shorter,
                    }),
                    ...(lgUp && {
                        // width: `calc(100% - ${NAV.WIDTH + 1}px)`,
                        height: HEADER.H_DESKTOP,
                    }),
                }}
            >
                <Toolbar
                    sx={{
                        height: 1,
                        px: { lg: 5 },
                    }}
                >
                    {renderContent}
                </Toolbar>
            </AppBar>
        </>
    );
}

Header.propTypes = {
    onOpenNav: PropTypes.func,
};


export default Header;