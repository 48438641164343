import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import store from "./redux/store/index.tsx";
import { Provider as ReduxProvider } from "react-redux";
import App from "./App.tsx";
import LoadingProcess from "~components/common/other/LoadingProcess.tsx";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <HelmetProvider>
        <React.StrictMode>
            <ReduxProvider store={store}>
                <BrowserRouter>
                    <Suspense fallback={<div><LoadingProcess /></div>}>
                        <App />
                    </Suspense>
                </BrowserRouter>
            </ReduxProvider>
        </React.StrictMode>
    </HelmetProvider>
);
