import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import OrderAPI from '~services/shoppingService/order_api';


// Thunks
// Lấy toàn bộ đơn hàng
export const fetchOrders = createAsyncThunk('orders/fetchOrders', async (_, { rejectWithValue }) => {
  try {
    const response = await OrderAPI.getAllOrder();
    if (response && response.status === 200) {
      return response.data;
    } else {
      return rejectWithValue("No valid data received");
    }
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Lấy đơn hàng theo mã người dùng
export const fetchOrdersByUserId = createAsyncThunk('orders/fetchOrdersByUserId', async (userId, { rejectWithValue }) => {
  try {
    const response = await OrderAPI.getAllOrderByUserId(userId);
    if (response) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Lấy đơn hàng của người dùng theo email
export const fetchUserOrders = createAsyncThunk('orders/fetchUserOrdersByEmail', async (userEmail, { rejectWithValue }) => {
  try {
    const response = await OrderAPI.getAllOrderByEmail(userEmail);
    if (response && response.status === 200) {
      return response.data;
    } else {
      return rejectWithValue("No valid data received");
    }
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Lấy chi tiết đơn hàng
export const fetchOrderDetails = createAsyncThunk('orders/fetchOrderDetails', async (orderId, { rejectWithValue }) => {
  try {
    const response = await OrderAPI.getOrderDetails(orderId);
    if (response && response.status === 200) {
      return response.data;
    } else {
      return rejectWithValue("No valid data received");
    }
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Cập nhật trạng thái đơn hàng
export const updateOrderStatus = createAsyncThunk('orders/updateOrderStatus', async ({ orderId, status }, { rejectWithValue }) => {
  try {
    const response = await OrderAPI.updateOrderStatus(orderId, status);
    if (response && response.status === 200) {
      return { orderId, status };
    } else {
      return rejectWithValue("No valid data received");
    }
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Hủy đơn hàng
export const cancelOrder = createAsyncThunk('orders/cancelOrder', async (orderId, { rejectWithValue }) => {
  try {
    const response = await OrderAPI.cancelOrder(orderId);
    if (response && response.status === 200) {
      return orderId;
    } else {
      return rejectWithValue("No valid data received");
    }
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});



const initialState = {
  amount: 0,
  defaultPaymentMethod: '',
  defaultPaymentMethodId: 1,
  defaultDeliveryMethod: '',
  defaultDeliveryMethodId: 1,
  defaultShippingAddress: '',
  cartItems: [],
  invoice: '',
  paymentResult: false,

  orders: [],
  orderDetails: {},
  isCEO: false,
  status: 'idle',
  error: null,

};


const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderDetails: (state, action) => {
      const { amount, defaultPaymentMethod, defaultDeliveryMethodId, defaultPaymentMethodId, defaultDeliveryMethod, defaultShippingAddress, cartItems, invoice } = action.payload;
      state.amount = amount;
      state.defaultPaymentMethod = defaultPaymentMethod;
      state.defaultPaymentMethodId = defaultPaymentMethodId;
      state.defaultDeliveryMethod = defaultDeliveryMethod;
      state.defaultDeliveryMethodId = defaultDeliveryMethodId;
      state.defaultShippingAddress = defaultShippingAddress;
      state.cartItems = cartItems;
      state.invoice = invoice;
    },
    resetOrderDetails: (state) => {
      state.amount = 0;
      state.defaultPaymentMethod = '';
      state.defaultPaymentMethodId = 1;
      state.defaultDeliveryMethod = '';
      state.defaultDeliveryMethodId = 1;
      state.defaultShippingAddress = '';
      state.cartItems = [];
      state.invoice = '';
      state.paymentResult = false;
    },
    paymentResult: (state) => {
      state.paymentResult = true;
    },

    setAdmin(state, action) {
      state.isCEO = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orders = action.payload;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchOrdersByUserId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrdersByUserId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orders = action.payload;
      })
      .addCase(fetchOrdersByUserId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchUserOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserOrders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orders = action.payload;
      })
      .addCase(fetchUserOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchOrderDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orderDetails = action.payload;
      })
      .addCase(fetchOrderDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        const { orderId, status } = action.payload;
        const order = state.orders.find(order => order.maDonHang === orderId);
        if (order) {
          order.trangThaiMoiNhat = status;
        }
        if (state.orderDetails.maDonHang === orderId) {
          state.orderDetails.trangThaiMoiNhat = status;
        }
      })
      .addCase(cancelOrder.fulfilled, (state, action) => {
        const orderId = action.payload;
        const order = state.orders.find(order => order.maDonHang === orderId);
        if (order) {
          order.trangThaiMoiNhat = 'Huy';
        }
        if (state.orderDetails.maDonHang === orderId) {
          state.orderDetails.trangThaiMoiNhat = 'Huy';
        }
      });
  },
});

export const { setAdmin, setOrderDetails, resetOrderDetails, paymentResult } = orderSlice.actions;
export default orderSlice.reducer;



// Selectors
export const selectOrders = state => state.orders.orders;
export const selectOrderDetails = state => state.orders.orderDetails;
export const selectIsAdmin = state => state.orders.isCEO;
export const selectOrderStatus = state => state.orders.status;
export const selectOrderError = state => state.orders.error;