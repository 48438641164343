import React from 'react';
import { IconButton } from '@mui/material';
import TiktokLogo from './image/tiktok.png';

const TiktokIcon = ({ url }) => {
  const handleClick = () => {
    window.location.href = url;
  };

  return (
    <IconButton onClick={handleClick}>
      <img src={TiktokLogo} alt="Tiktok" style={{ width: 24, height: 24 }} />
    </IconButton>
  );
};

export default TiktokIcon;
