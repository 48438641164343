import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Iconify from '~components/image/iconify/iconify.tsx';

// import { account } from '~components/_mock/account.tsx';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '~redux/store';
import { useNavigate } from 'react-router-dom';
import { logoutSuccess } from "~redux/slices/auth/authSlice";
import useAuth from '~hooks/auth/useAuth';
import { syncCartOnLogOut } from '~redux/slices/shopping/cartSlice';


// @ts-ignore
export default function AccountPopover({ menuControl }) {
    const [open, setOpen] = useState(null);

    const isCartOpen = useSelector((state: RootState) => state.cart.isCartOpen);
    const { isLogIn, userInfo, isCEO } = useAuth();




    // ----------------------------------------------------------------------
    const MENU_OPTIONS = [
        {
            path: "/userProfile/general",
            label: 'Thông tin tài khoản',
            icon: "healthicons:ui-user-profile-outline",
        },
        // {
        //     path: "/liked_item",
        //     label: 'Danh sách yêu thích',
        //     icon: "solar:chat-round-like-outline",
        // },
        // {
        //     path: "/shopping/order_history",
        //     label: 'Lịch sử mua hàng',
        //     icon: "carbon:product",
        // },
        ...(isCEO ? [{
            path: "/ceo/shopping-manager",
            label: 'Quản lý bán hàng',
            icon: "carbon:settings-edit",
        }] : []),
        // {
        //     path: "/userSetting",
        //     label: 'Cài Đặt',
        //     icon: "carbon:settings-edit",
        // },
    ];

    // ----------------------------------------------------------------------




    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleNavigateToLogin = () => {
        setOpen(null);
        navigate('/auth/signIn');
    };

    // @ts-ignore
    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);

    };

    const handleLogOut = async () => {
        setOpen(null);

        // Đồng bộ giỏ hàng trước khi đăng xuất
        //@ts-ignore
        await dispatch(syncCartOnLogOut()).unwrap();

        // Đăng xuất
        dispatch(logoutSuccess());

        // Redirect to home or sign-in page after logout
        window.location.href = '/';
    };

    //@ts-ignore
    const handleClickMenu = (option) => {
        setOpen(null);
        navigate(`${option.path}`)
    };

    return (
        <>
            <IconButton
                disabled={isCartOpen}
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    background: (theme) => alpha(theme.palette.grey[500], 0.08),
                    // @ts-ignore
                    ...(open && {
                        background: (theme) =>
                            `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    }),
                }}
            >
                <Avatar
                    src={
                        !isLogIn ?
                            `${process.env.PUBLIC_URL}/assets/images/avatars/guest_avatar2.svg`
                            : !isCEO ?
                                `${process.env.PUBLIC_URL}/assets/images/avatars/user_avatar3.svg`
                                : `${process.env.PUBLIC_URL}/assets/images/avatars/avatar_12.jpg`
                    }
                    alt={(userInfo as { userName: string }).userName}
                    sx={{
                        width: 36,
                        height: 36,
                        border: (theme) => `solid 2px ${theme.palette.background.default}`,
                    }}
                >
                    {typeof userInfo === 'object' && userInfo && 'userName' in userInfo && userInfo.userName.charAt(0).toUpperCase()}
                </Avatar>
            </IconButton>

            <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1,
                        ml: 0.75,
                        width: 200,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2 }}>
                    <Typography variant="subtitle2" noWrap>
                        {isLogIn ? (userInfo as { userName: string }).userName : "User chưa đăng nhập"}
                    </Typography>
                </Box>

                {menuControl ? (
                    <div>
                        <Divider sx={{ borderStyle: 'dashed' }} />

                        {
                            MENU_OPTIONS.map((option) => (
                                <MenuItem key={option.label} onClick={() => handleClickMenu(option)}>
                                    <div style={{ marginRight: "15px" }}>
                                        <Iconify
                                            //@ts-ignore
                                            icon={option.icon}
                                            width={24}
                                            height={24}
                                        ></Iconify>
                                    </div>
                                    {option.label}
                                </MenuItem>
                            ))
                        }


                        <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

                        <MenuItem
                            disableRipple
                            disableTouchRipple
                            onClick={handleLogOut}
                            sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
                        >
                            Đăng xuất
                        </MenuItem>
                    </div>
                ) : (
                    <MenuItem
                        disableRipple
                        disableTouchRipple
                        onClick={handleNavigateToLogin}
                        sx={{ typography: 'subtitle1', color: 'royalblue', py: 1.5 }}
                    >
                        Đăng nhập
                    </MenuItem>
                )}


            </Popover>
        </>
    )
        ;
}
