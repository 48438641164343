/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Main from '../component/main';
import Header from '../component/header';
import Footer from "~layouts/component/Footer";
// @ts-ignore
export default function CustomLayout({ children, isLogIn }) {
    const [openNav, setOpenNav] = useState(false);
    const [menuControl, setMenuControl] = useState(isLogIn);

    return (
        <>
            <Header menuControl={menuControl} onOpenNav={() => setOpenNav(true)} />

            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                    justifyContent: 'space-between',
                }}
            >
                {/* <Nav menuControl={menuControl} openNav={openNav} onCloseNav={() => setOpenNav(false)} /> */}

                {/*// @ts-ignore*/}
                <Main
                    sx={{
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {children}
                </Main>
            </Box>

            <Footer />
        </>
    );
}

CustomLayout.propTypes = {
    children: PropTypes.node,
};