import React from 'react';
import { IconButton } from '@mui/material';
import ShoppeeLogo from './image/shoppe.png';

const ShoppeeIcon = ({ url }) => {
  const handleClick = () => {
    window.location.href = url;
  };

  return (
    <IconButton onClick={handleClick}>
      <img src={ShoppeeLogo} alt="ShoppeeIcon" style={{ width: 24, height: 24 }} />
    </IconButton>
  );
};

export default ShoppeeIcon;
