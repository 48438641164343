import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DieuKienSalesService from "~services/shoppingService/dieuKienSale_api";

const initialState = {
  dieuKienSales: [],
  isLoading: false,
  selectedRowKeys: [],
  selectedDieuKienSales: [],
  errorMessage: [],
}

export const fetchDieuKienSales = createAsyncThunk(
  "dieuKienSale/fetchDieuKienSales",
  async () => {
    const response = await DieuKienSalesService.fetchDieuKienSales();
    return response;
  }
);

export const searchDieuKienSales = createAsyncThunk(
  "dieuKienSale/searchDieuKienSales",
  async ({ searchTerm, pageNumber, pageSize }) => {
    const response = await DieuKienSalesService.searchDieuKienSales(searchTerm, pageNumber, pageSize);
    return response;
  }
);

export const sortDieuKienSalesById = createAsyncThunk(
  "dieuKienSale/sortDieuKienSalesById",
  async ({ order, pageNumber, pageSize }) => {
    const response = await DieuKienSalesService.sortDieuKienSalesById(order, pageNumber, pageSize);
    return response;
  }
);

export const createDieuKienSale = createAsyncThunk(
  "dieuKienSale/createDieuKienSale",
  async ({ data }) => {
    const response = await DieuKienSalesService.createDieuKienSale(data);
    return response;
  }
);


export const updateDieuKienSale = createAsyncThunk(
  "dieuKienSale/updateDieuKienSale",
  async ({ id, data }) => {
    const response = await DieuKienSalesService.updateDieuKienSale(id, data);
    return response;
  }
);

export const deleteDieuKienSale = createAsyncThunk(
  "dieuKienSale/deleteDieuKienSale",
  async ({ id }) => {
    await DieuKienSalesService.deleteDieuKienSale(id);
    return id; // Trả về dieuKienSaleId sau khi đã xóa
  }
);

const dieuKienSaleSlice = createSlice({
  name: "dieuKienSale",
  initialState,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedDieuKienSales = action.payload;
    },
    clearSelectedRows(state) {
      state.selectedRows = [];
      state.selectedRowKeys = [];
    },
    logout: (state) => {
      state.dieuKienSales = null;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDieuKienSales.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDieuKienSales.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dieuKienSales = action.payload;
      })
      .addCase(fetchDieuKienSales.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      })
      .addCase(searchDieuKienSales.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchDieuKienSales.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dieuKienSales = action.payload;
      })
      .addCase(searchDieuKienSales.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(sortDieuKienSalesById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sortDieuKienSalesById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dieuKienSales = action.payload;
      })
      .addCase(sortDieuKienSalesById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createDieuKienSale.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createDieuKienSale.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createDieuKienSale.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateDieuKienSale.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(updateDieuKienSale.fulfilled, (state) => {
        state.isLoading = false;
        state.status = 'succeeded';
      })
      .addCase(updateDieuKienSale.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteDieuKienSale.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteDieuKienSale.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteDieuKienSale.rejected, (state, error) => {
        state.status = 'failed';
        state.errorMessage = error;
      })
  },
});
const { reducer } = dieuKienSaleSlice;
export default reducer;

export const { clearSelectedRows } = dieuKienSaleSlice.actions;
export const { setSelectedRowKeys } = dieuKienSaleSlice.actions;
export const { setSelectedRows } = dieuKienSaleSlice.actions;
