import React from 'react';
import { IconButton } from '@mui/material';
import ZaloLogo from './image/Zalo.png'; 

const ZaloIcon = ({ url }) => {
  const handleClick = () => {
    window.location.href = url;
  };

  return (
    <IconButton onClick={handleClick}>
      <img src={ZaloLogo} alt="Zalo" style={{ width: 24, height: 24 }} />
    </IconButton>
  );
};

export default ZaloIcon;
