/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import { usePathname } from '~routes/hooks/use-pathname.tsx';
import RouterLink from '~routes/components/router-link.tsx';
import Logo from '~components/common/logo/logo.tsx';
import Scrollbar from '~components/common/scrollbar/scrollbar.tsx';
import { NAV } from '../component/config-layout.tsx';
import navConfig from './config-navigation.tsx';
import { getUserName } from '~utils/helper.js';


// @ts-ignore
const Nav = ({ openNav, onCloseNav }) => {
    const pathname = usePathname();

    const userName = getUserName();
    const randomAvatar = Math.floor(Math.random() * 25) + 1;

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
    }, [pathname]);

    const renderAccount = (
        <Box
            sx={{
                my: 3,
                mx: 2.5,
                py: 2,
                px: 2.5,
                display: 'flex',
                borderRadius: 1.5,
                alignItems: 'center',
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
            }}
        >
            <Avatar src={`${process.env.PUBLIC_URL}/assets/images/avatars/avatar_${randomAvatar}.jpg`} alt='avatar' />

            <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2">Admin: {userName}</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {/* {
                        // @ts-ignore
                        account.role} */}
                </Typography>
            </Box>
        </Box>
    );

    const renderMenu = (
        <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
            {navConfig.map((item) => (
                <NavItem key={item.title} item={item} />
            ))}
        </Stack>
    );

    const renderContent = (
        <
            // @ts-ignore
            Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <
                // @ts-ignore
                Logo sx={{ mt: 3, ml: 4 }} />
            {renderAccount}
            {renderMenu}
            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    return (
        <Box
            sx={{
                flexShrink: { lg: 0 },
                width: { up: NAV.WIDTH },
            }}
        >
            <Drawer
                open={openNav}
                onClose={onCloseNav}
                PaperProps={{
                    sx: {
                        width: NAV.WIDTH,
                    },
                }}
            >
                {renderContent}
            </Drawer>
        </Box>
    );
}

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

// ----------------------------------------------------------------------

function NavItem(
    // @ts-ignore
    { item }) {
    const pathname = usePathname();
    const active = item.path === pathname;

    return (
        // @ts-ignore
        <ListItemButton
            component={RouterLink}
            href={item.path}
            sx={{
                minHeight: 44,
                borderRadius: 0.75,
                typography: 'body2',
                color: 'text.secondary',
                textTransform: 'capitalize',
                fontWeight: 'fontWeightMedium',
                ...(active && {
                    color: 'primary.main',
                    fontWeight: 'fontWeightSemiBold',
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                    '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                    },
                }),
            }}
        >
            <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
                {item.icon}
            </Box>

            <Box component="span">{item.title} </Box>


        </ListItemButton>
    );
}

NavItem.propTypes = {
    item: PropTypes.object,
};

export default Nav