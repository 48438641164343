/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// @ts-ignore
export function useResponsive(query, start, end) {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(start));
  const mediaDown = useMediaQuery(theme.breakpoints.down(start));
  const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));
  const mediaOnly = useMediaQuery(theme.breakpoints.only(start));

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  return mediaOnly;
}

// ----------------------------------------------------------------------

export function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  const [width, setWidth] = useState('xs');

  const matchXl = useMediaQuery(theme.breakpoints.up('xl'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchXs = useMediaQuery(theme.breakpoints.up('xs'));

  useEffect(() => {
    if (matchXl) setWidth('xl');
    else if (matchLg) setWidth('lg');
    else if (matchMd) setWidth('md');
    else if (matchSm) setWidth('sm');
    else if (matchXs) setWidth('xs');
    else setWidth('xs');
  }, [matchXl, matchLg, matchMd, matchSm, matchXs]);

  return width;
}
