import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";

const fetchDieuKienSales = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/dieuKienSale/1`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        );
        const data = response.data;
        return data;
    } catch (error) {
        throw new Error('failed');
    }
};

const searchDieuKienSales = async (searchTerm, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/dieuKienSale?sort=madieuKienSale,asc&search=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        );
        return response.data;
    } catch (error) {
        throw new Error('Failed to search DieuKienSales');
    }
};

const createDieuKienSale = async (data) => {
    try {
        const token = getAccessToken();
        const response = await api.post('/dieuKienSale', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createdProduct = response.data;
        return createdProduct;
    } catch (error) {
        throw new Error('Creating DieuKienSales failed');
    }
};


const updateDieuKienSale = async (id, data) => {
    try {
        const token = getAccessToken();
        const response = await api.put(`/dieuKienSale/1`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Change DieuKienSales failed');
    }
};

const deleteDieuKienSale = async (id) => {
    try {
        const token = getAccessToken();
        await api.delete(`/dieuKienSale/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return 'DieuKienSales deleted successfully';
    } catch (error) {
        throw new Error('Deleting DieuKienSales failed');
    }
};

const DieuKienSalesService = {
    fetchDieuKienSales,
    searchDieuKienSales,
    createDieuKienSale,
    updateDieuKienSale,
    deleteDieuKienSale
}
export default DieuKienSalesService;