import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CampaignsService from "~services/shoppingService/campaign_api";
import PromotionsService from "~services/shoppingService/promotion_api";


  
const initialState = {
  promotions: [],
  campaigns: [],
  isLoading: false,
  selectedRowKeys: [],
  selectedCampaigns: [],
  errorMessage: [],
}

export const fetchCampaigns = createAsyncThunk(
  "campaign/fetchCampaigns",
  async () => {
    const response = await CampaignsService.fetchCampaigns();
    return response;
  }
);

export const fetchPromotions = createAsyncThunk(
  "campaign/fetchPromotions",
  async () => {
    const response = await PromotionsService.getPromotions();
    return response.data;
  }
);

export const searchCampaigns = createAsyncThunk(
  "campaign/searchCampaigns",
  async ({ searchTerm, pageNumber, pageSize }) => {
    const response = await CampaignsService.searchCampaigns(searchTerm, pageNumber, pageSize);
    return response;
  }
);

export const sortCampaignsById = createAsyncThunk(
  "campaign/sortCampaignsById",
  async ({ order, pageNumber, pageSize }) => {
    const response = await CampaignsService.sortCampaignsById(order, pageNumber, pageSize);
    return response;
  }
);

export const createCampaign = createAsyncThunk(
  "campaign/createCampaign",
  async ({data}) => {
    const response = await CampaignsService.createCampaign(data);
    return response;
  }
);


export const updateCampaign = createAsyncThunk(
  "campaign/updateCampaign",
  async ({ id, data }) => {
    const response = await CampaignsService.updateCampaign(id, data);
    return response;
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaign/deleteCampaign",
  async ({id}) => {
    await CampaignsService.deleteCampaign(id);
    return id; // Trả về campaignId sau khi đã xóa
  }
);

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedCampaigns = action.payload;
    },
    clearSelectedRows(state) {
      state.selectedRows = [];
      state.selectedRowKeys = [];
    },
    logout: (state) => {
      state.campaigns = null;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaigns.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.campaigns = action.payload;
      })
      .addCase(fetchCampaigns.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      })
      .addCase(searchCampaigns.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchCampaigns.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.campaigns = action.payload;
      })
      .addCase(searchCampaigns.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(sortCampaignsById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sortCampaignsById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.campaigns = action.payload;
      })
      .addCase(sortCampaignsById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createCampaign.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCampaign.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createCampaign.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateCampaign.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(updateCampaign.fulfilled, (state) => {
        state.isLoading = false;
        state.status = 'succeeded';
      })
      .addCase(updateCampaign.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteCampaign.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCampaign.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteCampaign.rejected, (state, error) => {
        state.status = 'failed';
        state.errorMessage = error;
      })
  },
});
const { reducer } = campaignSlice;
export default reducer;

export const { clearSelectedRows } = campaignSlice.actions;
export const { setSelectedRowKeys } = campaignSlice.actions;
export const { setSelectedRows } = campaignSlice.actions;
