import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";



const GetAll_Size = async (pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/GetAll_Size`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    const ProData = response;
    return ProData;
  } catch (error) {
    throw new Error('failed');
  }
};

const fetchProducts = async (pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/?sort=maSanPham,asc&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    const ProData = response.data.content;
    return ProData;
  } catch (error) {
    throw new Error('failed');
  }
};

const fetchProductsHome = async (pageSize) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham?sort=maSanPham,desc&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    const ProData = response.data;
    return ProData;
  } catch (error) {
    throw new Error('failed');
  }
};

const getProductByID = async (id) => {
  const token = getAccessToken();
  try {
    const response = await api.get(`/SanPham/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    const productData = response.data;
    return productData;
  } catch (error) {
    throw new Error('Failed to get product by ID');
  }
};



const createProduct = async (data) => {
  try {
    const token = getAccessToken();
    const response = await api.post('/SanPham', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const createdProduct = response.data;
    return createdProduct;
  } catch (error) {
    throw new Error('Creating product failed');
  }
};


const changeStatus = async (id, data) => {
  try {
    const token = getAccessToken();
    const response = await api.patch(`/SanPham/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Change Status product failed');
  }
};

const updateProduct = async (id, data) => {
  try {
    const token = getAccessToken();
    const response = await api.patch(`/SanPham/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Change Status product failed');
  }
};

const searchProducts = async (searchTerm, pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham?sort=maSanPham,asc&search=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to search products');
  }
};


const searchProductsByQrCode = async (qrCode) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/searchByQrCode?sort=maSanPham,asc&searchByQrCode=${qrCode}&pageNumber=${1}&pageSize=${1000}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to search products');
  }
};


const CEO_searchProducts = async (searchTerm, pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/CEO-GetAll?sort=maSanPham,asc&search=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to search products');
  }
};



const sortProductsByPrice = async (order, pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    console.log(order);
    const sortOption = order === 'asc' ? 'gia,asc' : 'gia,desc';
    const response = await api.get(`/SanPham?sort=${sortOption}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to sort products by Price');
  }
};

const sortProductsById = async (order, pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    const sortOption = order === 'asc' ? 'id,asc' : 'id,desc';
    const response = await api.get(`/SanPham?sort=${sortOption}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to sort products by id');
  }
};

const sortProductsByStatus = async (order, pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham?trangThai=${order}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to sort products by Status');
  }
};

const sortProductsByIdCategory = async (order, pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham?maLoaiSanPham=${order}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to sort products by id Category');
  }
};

const getProductById = async (id) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Không lấy được sản phẩm');
  }
};

const getAllProduct = async (pageSize, pageNumber) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham?sort=maSanPham,asc&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    const ProData = response.data;
    return ProData;
  } catch (error) {
    throw new Error('failed');
  }
};

const fetchProductsCEO = async () => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    const ProData = response.data;
    return ProData;
  } catch (error) {
    throw new Error('failed');
  }
};

// Hàm lấy thông tin thương hiệu của sản phẩm theo id
const getProductMakerByProductId = (id) => {
  const token = getAccessToken();
  return api.get(`/ThuongHieu/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  );
};

// Hàm lấy thông tin danh mục sản phẩm theo id
const getProductCategoryByProductId = (id) => {
  const token = getAccessToken();
  return api.get(`/LoaiSanPham/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  );
};

// Hàm xóa sản phẩm theo id
const deleteProduct = (id) => {
  const token = getAccessToken();
  return api.delete(`/SanPham/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  );
};


// Hàm lấy sản phẩm đã thích theo id người dùng
const getLikedProductByUserId = async (maNguoiDung) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/SanPhamDaThich/${maNguoiDung}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching post:', error.response || error.message);
    throw new Error('Failed to fetch post');
  }
};

// Hàm lấy các sản phẩm tương tương tự
const getProposeProducts = async (maSanPham) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/${maSanPham}/SanPhamDeXuat`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching post:', error.response || error.message);
    throw new Error('Failed to fetch post');
  }
};

// Hàm lấy thông tin sản phẩm đã xem của người dùng
const getViewedProductByUserId = async (maNguoiDung) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/SanPhamDaXem/${maNguoiDung}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching get:', error.response || error.message);
    throw new Error('Failed to fetch get');
  }
};


// Hàm cập nhật sản phẩm đã xem và đã thích
const updateDaXemVaDaThich = async (userId, sanPhamDaXem, sanPhamDaThich) => {
  try {
    const token = getAccessToken();
    const response = await api.post(`/SanPham/DaThichVaDaXem/${userId}`, { sanPhamDaXem, sanPhamDaThich }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching get:', error.response || error.message);
    throw new Error('Failed to fetch get');
  }
}




// Hàm lấy thông tin sản phẩm khác
const getOtherOption = async (id) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/${id}/otherOption`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching get:', error.response || error.message);
    throw new Error('Failed to fetch get');
  }
};

const ProductService = {
  GetAll_Size,
  fetchProducts,
  getProductByID,
  fetchProductsHome,
  createProduct,
  changeStatus,
  updateProduct,
  searchProducts,
  searchProductsByQrCode,
  CEO_searchProducts,
  sortProductsByPrice,
  sortProductsById,
  sortProductsByStatus,
  sortProductsByIdCategory,
  getProductById,
  getAllProduct,
  fetchProductsCEO,
  getProductMakerByProductId,
  getProductCategoryByProductId,
  deleteProduct,
  getLikedProductByUserId,
  getProposeProducts,
  updateDaXemVaDaThich,
  getViewedProductByUserId,
  getOtherOption
}

export default ProductService;