import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";

const getPromotions = async () => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/KhuyenMai?sort=maSuKien,asc`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    const data = response;
    return data;
  } catch (error) {
    throw new Error('failed');
  }
};


const createPromotion = async (data) => {
  try {
    const token = getAccessToken();
    const response = await api.post('/KhuyenMai', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const createdProduct = response.data;
    return createdProduct;
  } catch (error) {
    throw new Error('Creating Campaigns failed');
  }
};


const updatePromotion = async (id, data) => {
  try {
    const token = getAccessToken();
    const response = await api.patch(`/KhuyenMai/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Change Campaigns failed');
  }
};

const deletePromotion = async (id) => {
  try {
    const token = getAccessToken();
    await api.delete(`/KhuyenMai/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return 'Campaigns deleted successfully';
  } catch (error) {
    throw new Error('Deleting Campaigns failed');
  }
};

const getPromotionProducts = async () => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPhamKhuyenMai?sort=maSuKien,asc`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error('failed');
  }
};


const createPromotionProduct = async (data) => {
  try {
    const token = getAccessToken();
    const response = await api.post('/SanPhamKhuyenMai', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const createdProduct = response.data;
    return createdProduct;
  } catch (error) {
    throw new Error('Creating Campaigns failed');
  }
};


const updatePromotionProduct = async (id, data) => {
  try {
    const token = getAccessToken();
    const response = await api.patch(`/SanPhamKhuyenMai/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Change Campaigns failed');
  }
};

const deletePromotionProduct = async (id) => {
  try {
    const token = getAccessToken();
    await api.delete(`/SanPhamKhuyenMai/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return 'Campaigns deleted successfully';
  } catch (error) {
    throw new Error('Deleting Campaigns failed');
  }
};

const PromotionsService = {
  getPromotions,
  createPromotion,
  updatePromotion,
  deletePromotion,
  getPromotionProducts,
  createPromotionProduct,
  updatePromotionProduct,
  deletePromotionProduct,
}
export default PromotionsService;
