import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import RouterLink from '~components/common/router-link/router-link.tsx';

// @ts-ignore
const Logo = forwardRef(({ disabledLink = false, sx }) => {
  const currentPath = window.location.pathname;
  let pathToReturn = '/';

  if (currentPath.startsWith('/shopping')) {
    pathToReturn = '/shopping';
  } else if (currentPath.startsWith('/blog')) {
    pathToReturn = '/blog';
  } else if (currentPath.startsWith('/ceo')) {
    pathToReturn = '/ceo';
  } else {
    pathToReturn = '/';
  }

  const logo = (
    // <Box
    //     component="img"
    //     src={process.env.PUBLIC_URL + "/logo.svg"}
    //     sx={{ width: 200, height: 60, cursor: 'pointer', ...sx }}
    // />
    <Box
      component="img"
      // src={process.env.PUBLIC_URL + "/logo21.png"}
      // @ts-ignore
      src="https://firebasestorage.googleapis.com/v0/b/teepressso.appspot.com/o/Main%2Ftrasua.png?alt=media&token=06eb1349-87f0-40dc-afc9-a09d98f051a1"
      sx={{ width: 240, height: 75, cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    // @ts-ignore
    <Link
      component={RouterLink}
      href={pathToReturn}
      sx={{ display: 'contents' }}
    >
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  // @ts-ignore
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
