import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';
import LoadingProcess from "~components/common/other/LoadingProcess";
import AdminLayout from '~layouts/adminLayout/index';
import CustomLayout from '~layouts/commonLayout/index';
import useAuth from '~hooks/auth/useAuth';

//======================= Khai báo các đường dẫn của trang ======================//
const ROUTE_PATHS = {
  // Admin
  CEO: 'ceo', // Trang chủ CEO
  CEO_SHOPPING: 'shopping-manager', // Trang chủ shopping của CEO
  PRODUCT_MANAGER: 'product-manager', // Quản lý sản phẩm
  USER_MANAGER: 'user-manager', // Quản lý người dùng
  ORDER_MANAGER: 'order-manager', // Quản lý đơn hàng
  ORDER_MANAGER_RUTGON: 'order-manager-rutgon', // Quản lý đơn hàng rút gọn
  CATEGORY_MANAGER: 'categories-manager', // Quản lý danh mục
  CAMPAIGN_MANAGER: 'campaign-manager', // Quản lý sự kiện
  CUPON_MANAGER: 'cupon-manager', // Quản lý khuyến mãi
  DIEUKIENSALE_MANAGER: 'dieukien-sale-manager', // Quản lý điều kiện sale

  //Blog
  BLOG_ABOUT: 'about', // Trang giới thiệu tổng thể trang web
  BLOG_CONTACT: 'contact', // Trang liên hệ

  //Shopping
  SHOPPING: 'shopping', // Trang chủ shopping
  GROUP_ORDER: 'group_order', // Trang đặt hàng theo nhóm
  CHECKOUT: 'checkout', // Trang thanh toán
  ORDER: 'order', // Trang đặt hàng
  QUANGCAO: 'quangcao', // Trang quảng cáo
  PRODUCT_DETAIL: 'productDetail', // Trang chi tiết sản phẩm
  ORDER_HISTORY: 'order_history', // Trang lịch sử đơn hàng
  CART: 'cart', // Trang giỏ hàng
  VNPAY_RESULT: 'vnpay-result', // Trang kết quả thanh toán VNPay

  CAMPAIGN_PAGE: 'campaigns', // Trang danh mục sản phẩm
  COUPON_PAGE: 'cupons', // Trang danh mục sản phẩm

  //User
  USER_PROFILE: 'userProfile', // Trang thông tin người dùng
  USER_GENERAL: '/userProfile/general', // Trang thông tin chung
  USER_CHANGE_PASSWORD: '/userProfile/change-password', // Trang thay đổi mật khẩu
  USER_CHANGE_EMAIL: '/userProfile/change-email', // Trang thay đổi email
  USER_ADDRESSLIST: '/userProfile/address-book', // Trang sổ địa chỉ


  //Other
  LOGIN: 'auth/signIn', // Trang đăng nhập
  SIGN_UP: 'auth/signUp', // Trang đăng ký
  RESET_PASSWORD: 'resetPassword', // Trang đặt lại mật khẩu
  CHANGE_PASSWORD: 'changePassword', // Trang thay đổi mật khẩu
  LIKED_PRODUCT: 'liked_product', // Trang sản phẩm đã thích
  ERROR_404: '404', // Trang lỗi 404
  ERROR_500: '500', // Trang lỗi 500

  PAYMENTPOLICE: 'payment_police', // Trang bảo vệ thông tin thanh toán
  USERINFORPOLICE: 'user_infor_police', // Trang bảo vệ thông tin cá nhân
  SECURITY_POLICE: 'security_police', // Trang bảo mật
  SHIPPING_POLICE: 'shipping_police', // Trang bảo vệ thông tin vận chuyển
  CANCEL_POLICE: 'cancel_police', // Trang bảo vệ thông tin hoàn hủy
  HOW_TO_PAY: 'how_to_pay', // Trang hướng dẫn thanh toán
};


//======================= Khai báo các component được load theo từng trang ======================//
// Quản lý các component được load theo từng trang
const LazyComponents = {

  //Shopping
  ShoppingPage: lazy(() => import('~pages/shoppingPage/home/shopping_HomePage')),
  GroupOrder: lazy(() => import('~components/shopping/muachung/GroupOrder')),
  Content: lazy(() => import('~pages/shoppingPage/home/components/Content')),
  CartPage: lazy(() => import('~pages/shoppingPage/cart/CartPage')),
  Checkout: lazy(() => import('~pages/shoppingPage/checkout_payment/Checkout')),

  //Ceo
  CEO_HOME_PAGE: lazy(() => import('~pages/commonPage/adminPage/CEO_HomePage/CEO_HomePage')),
  CEO_SHOPPING_HOME_PAGE: lazy(() => import('~pages/commonPage/adminPage/CEO_HomePage/CEO_Shopping_HomePage')),
  UserManager: lazy(() => import('~pages/commonPage/adminPage/user_manager/index')),
  DieuKienSaleManager: lazy(() => import('~pages/commonPage/adminPage/dieuKienSale_manager')),
  CampaignManager: lazy(() => import('~pages/commonPage/adminPage/campaign_manager/index')),
  CuponManager: lazy(() => import('~pages/commonPage/adminPage/cupon_manager/index')),
  ProductManager: lazy(() => import('~pages/commonPage/adminPage/product_manager')),
  OrderManager: lazy(() => import('~pages/commonPage/adminPage/order_manager_rutgon/index')),
  CategoryManager: lazy(() => import('~pages/commonPage/adminPage/product_category_manager/index')),


  //Other
  LoginPage: lazy(() => import('~pages/commonPage/auth/logInPage/login')),
  SignUpPage: lazy(() => import('~pages/commonPage/auth/signUpPage/sign_up')),
  Page404: lazy(() => import('~pages/commonPage/errorPage/Page404')),
  Page500: lazy(() => import('~pages/commonPage/errorPage/Page500')),
  ResetPassWord: lazy(() => import('~pages/commonPage/auth/resetPassword/ResetPassword')),
  ChangePassWord: lazy(() => import('~pages/commonPage/auth/changePassword/changePassword')),

  //Footer
  PaymentPolice: lazy(() => import('~layouts/component/BaoVeThongTinThanhToan')),
  UserInforPolice: lazy(() => import('~layouts/component/BaoVeThongTinCaNhan')),
  SecurityPolice: lazy(() => import('~layouts/component/ChinhSachBaoMatTongHop')),
  ShippingPolice: lazy(() => import('~layouts/component/ChinhSachGiaoVan')),
  CancelPolice: lazy(() => import('~layouts/component/ChinhSachHoanHuy')),
  HowToPay: lazy(() => import('~layouts/component/CachThanhToanBangVnPay')),


  BlogAbout: lazy(() => import('~layouts/component/About')),
  BlogContact: lazy(() => import('~layouts/component/Contact')),


  //User
  UserInfo: lazy(() => import('~pages/userPage/user-Info/MyProfile')),
  UserGeneral: lazy(() => import('~pages/userPage/user-Info/ProfileForm')),
  UserChangePassWord: lazy(() => import('~pages/userPage/user-Info/ChangePassword')),
  UserChangeEmail: lazy(() => import('~pages/userPage/user-Info/ChangeEmail')),
  UserAddressList: lazy(() => import('~pages/userPage/user-Info/AddressList')),
  VnPayResult: lazy(() => import('~components/_payment/vn-QrPay/QRPayResult')),
};


//=================================== Quản lý đường dẫn của toàn bộ trang ===================================//
export default function Router() {
  //Kiểm tra xem người dùng đã đăng nhập chưa và có phải CEO không
  const { isLogIn, isCEO } = useAuth();

  //Dùng useRoutes để quản lý các đường dẫn của trang
  const routes = useRoutes([
    //==================================== Các đường dẫn của CEO ====================================//
    {
      path: ROUTE_PATHS.CEO,
      element: isLogIn && isCEO ? (
        //Nếu đã đăng nhập và là CEO thì hiển thị layout của CEO
        <AdminLayout isLogIn={isLogIn} isCEO={isCEO}>
          <Suspense fallback={<div><LoadingProcess /></div>}>
            <Outlet />
          </Suspense>
        </AdminLayout>
      ) : (
        //Nếu chưa đăng nhập hoặc không phải CEO thì chuyển hướng về trang đăng nhập
        <Navigate to={ROUTE_PATHS.LOGIN} />),
      children: [
        // Trang chủ CEO
        { element: <LazyComponents.CEO_HOME_PAGE />, index: true },

        // Quản lý người dùng của CEO
        { path: ROUTE_PATHS.USER_MANAGER, element: <LazyComponents.UserManager /> },


        {
          // Trang quản lý shopping của CEO
          path: ROUTE_PATHS.CEO_SHOPPING,
          element:
            <Suspense fallback={<div><LoadingProcess /></div>}>
              <Outlet />
            </Suspense>
          , children: [
            // Trang chủ quản lý shopping của CEO
            { element: <LazyComponents.CEO_SHOPPING_HOME_PAGE />, index: true },

            // Trang quản lý sản phẩm
            { path: ROUTE_PATHS.PRODUCT_MANAGER, element: <LazyComponents.ProductManager /> },

            // Trang quản lý đơn hàng rút gọn
            { path: ROUTE_PATHS.ORDER_MANAGER_RUTGON, element: <LazyComponents.OrderManager /> },

            // Trang quản lý danh mục
            { path: ROUTE_PATHS.CATEGORY_MANAGER, element: <LazyComponents.CategoryManager /> },

            // Trang quản lý thương hiệu
            { path: ROUTE_PATHS.DIEUKIENSALE_MANAGER, element: <LazyComponents.DieuKienSaleManager /> },

            // Trang quản lý chiến dịch quảng cáo
            { path: ROUTE_PATHS.CAMPAIGN_MANAGER, element: <LazyComponents.CampaignManager /> },

            // Trang quản lý cupon
            { path: ROUTE_PATHS.CUPON_MANAGER, element: <LazyComponents.CuponManager /> }

            // Trang quản lý khuyến mãi
            // { path: ROUTE_PATHS.SUPPLIER_MANAGER, element: <LazyComponents.SupplierManager /> },
          ]
        },

      ],
    },


    //==================================== Các đường dẫn chung ====================================//
    {
      path: '/',
      element:
        // Nếu đã đăng nhập thì hiển thị layout chung
        <CustomLayout isLogIn={isLogIn}>
          <Suspense fallback={<div><LoadingProcess /></div>}>
            <Outlet />
          </Suspense>
        </CustomLayout>,
      children: [

        // Trang chủ Teespresso Home Page
        { element: <LazyComponents.ShoppingPage />, index: true },

        // Trang mua chung
        { path: `${ROUTE_PATHS.GROUP_ORDER}`, element: <LazyComponents.GroupOrder /> },

        // Trang đăng ký
        { path: ROUTE_PATHS.LOGIN, element: <LazyComponents.LoginPage /> },

        // Trang đăng nhập
        { path: ROUTE_PATHS.SIGN_UP, element: <LazyComponents.SignUpPage /> },

        // Trang đặt lại mật khẩu
        { path: ROUTE_PATHS.RESET_PASSWORD, element: <LazyComponents.ResetPassWord /> },

        // Trang thanh toán VNPay
        { path: ROUTE_PATHS.VNPAY_RESULT, element: <LazyComponents.VnPayResult /> },

        // Trang thông tin info người dùng
        {
          path: ROUTE_PATHS.USER_PROFILE, element: <LazyComponents.UserInfo />, children: [

            { element: <LazyComponents.UserInfo /> },

            // Trang thông tin chung
            { path: ROUTE_PATHS.USER_GENERAL, element: <LazyComponents.UserGeneral /> },

            // Trang thay đổi mật khẩu
            { path: ROUTE_PATHS.USER_CHANGE_PASSWORD, element: <LazyComponents.UserChangePassWord /> },

            // Trang thay đổi email
            { path: ROUTE_PATHS.USER_CHANGE_EMAIL, element: <LazyComponents.UserChangeEmail /> },

            // Trang sổ địa chỉ người dùng
            { path: ROUTE_PATHS.USER_ADDRESSLIST, element: <LazyComponents.UserAddressList /> },
          ]
        },



        //=========================== Các đường dẫn chung không cần đăng nhập =========================//
        //Footer
        // Trang cam kết bảo vệ thông tin thanh toán của người dùng
        { path: ROUTE_PATHS.PAYMENTPOLICE, element: <LazyComponents.PaymentPolice /> },

        // Trang cam kết bảo vệ thông tin cá nhân của người dùng
        { path: ROUTE_PATHS.USERINFORPOLICE, element: <LazyComponents.UserInforPolice /> },

        // Trang chính sách bảo mật
        { path: ROUTE_PATHS.SECURITY_POLICE, element: <LazyComponents.SecurityPolice /> },

        // Trang chính sách vận chuyển
        { path: ROUTE_PATHS.SHIPPING_POLICE, element: <LazyComponents.ShippingPolice /> },

        // Trang chính sách hoàn hủy
        { path: ROUTE_PATHS.CANCEL_POLICE, element: <LazyComponents.CancelPolice /> },

        // Trang hướng dẫn thanh toán
        { path: ROUTE_PATHS.HOW_TO_PAY, element: <LazyComponents.HowToPay /> },

        // Trang giới thiệu tổng thể trang web
        { path: `${ROUTE_PATHS.BLOG_ABOUT}`, element: <LazyComponents.BlogAbout /> },

        // Trang liên hệ
        { path: `${ROUTE_PATHS.BLOG_CONTACT}`, element: <LazyComponents.BlogContact /> },


        //Shopping - Khách có thể xem sản phẩm mà không cần đăng nhập
        {
          path: ROUTE_PATHS.SHOPPING,
          children: [
            // Trang thanh toán
            { path: ROUTE_PATHS.CHECKOUT, element: <LazyComponents.Checkout /> },

            // Trang giỏ hàng
            //@ts-ignore
            { path: ROUTE_PATHS.CART, element: <LazyComponents.CartPage /> },
          ]
        },

      ]
    },


    // Trang lỗi 404 - Không tìm thấy trang
    { path: ROUTE_PATHS.ERROR_404, element: <LazyComponents.Page404 /> },
    { path: ROUTE_PATHS.ERROR_500, element: <LazyComponents.Page500 /> },

    // Trả tất cả các đường dẫn không hợp lệ về trang lỗi 404
    { path: "*", element: <LazyComponents.Page404 /> },
  ]);

  return routes;
}