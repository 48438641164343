import React, { useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Badge from '@mui/material/Badge';
import { useSelector, useDispatch } from 'react-redux';
import { toggleCartPopup } from '~redux/slices/shopping/cartSlice';
import { RootState } from '~redux/store';
import CartPreview from '~components/shopping/cartPreview/CartPreview';
import IconButton from '@mui/material/IconButton';
import Iconify from '~components/image/iconify/iconify.tsx';


//--------------------------CartPopover--------------------------//
interface HeaderProps {
    cartBounce: boolean;
}

interface CartItemType {
    donGia: number;
    soLuong: number;
    isMuaSau: boolean;
}


const CartPopover: React.FC<HeaderProps> = (props) => {
    const cartItems = useSelector((state: RootState) => state.cart.items as CartItemType[]);
    // @ts-ignore
    const [cartQuantity, setCartQuantity] = React.useState(0);

    const [openCart, setOpenCart] = React.useState(false);

    useEffect(() => {
        // @ts-ignore
        const soLuong = cartItems.filter(item => item.isMuaSau === false).length;
        setCartQuantity(soLuong);
    }, [cartItems]);

    // @ts-ignore
    const isCartOpen = useSelector(state => state.cart.isCartOpen);
    const dispatch = useDispatch();

    // @ts-ignore
    const handleOpen = () => {
        dispatch(toggleCartPopup());
    };

    const handleClose = () => {
        dispatch(toggleCartPopup());
    };

    useEffect(() => {
        setOpenCart(isCartOpen);
    }, [isCartOpen]);

    return (
        <div>
            <IconButton
                color={isCartOpen ? 'primary' : 'default'}
                onClick={handleOpen}
            >
                <Badge badgeContent={cartQuantity} color="error">
                    <Iconify
                        // @ts-ignore
                        width={26} icon="uil:cart" />
                </Badge>
            </IconButton>


            <Popover
                open={openCart}
                anchorEl={isCartOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {isCartOpen && <CartPreview closeCart={handleClose} />}
            </Popover>
        </div>
    );
}

export default CartPopover;